import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Slide, Alignment, Box } from '@resident-advisor/design-system'
import chunk from 'lodash/chunk'
import FullWidth from '@/components/generic/full-width'
import HorizontalList from '../horizontal-list/HorizontalList'

const GridList = ({
  Card,
  Button,
  items,
  mCols,
  ...gridProps
}: GridListProps) => {
  const slideRef = useRef([])

  const itemsByRows = chunk(items, mCols)

  return (
    <>
      <Box display={{ s: 'block', m: 'none' }}>
        <HorizontalList
          Card={Card}
          Button={Button}
          items={items}
          {...gridProps}
        />
      </Box>
      <Box display={{ s: 'none', m: 'block' }}>
        <FullWidth sizes={['s']}>
          <Alignment flexDirection="column">
            {itemsByRows.map((row, index) => (
              <Slide
                key={`row-${row[0].id}`}
                {...gridProps}
                mCols={mCols}
                ref={(el) => {
                  slideRef.current[index] = el
                }}
                pb={{ s: 3, m: 4 }}
              >
                {row.map((item, i) => {
                  // Slide displays 2 Items on screen initially when in horizontal mode
                  // so only pass component ref to items that are off-screen on initial render
                  // we also need to take into account the lazy load offset, so we can add 1 to the required index
                  // otherwise no ref is passed (document is used instead)
                  const lazyRootRef = i >= 3 ? slideRef[index] : undefined
                  return (
                    <Slide.Item key={item.id || i}>
                      <Card
                        lazyRootRef={lazyRootRef}
                        position={i + 1}
                        {...item}
                      />
                    </Slide.Item>
                  )
                })}
              </Slide>
            ))}
          </Alignment>
          {Button}
        </FullWidth>
      </Box>
    </>
  )
}

const gridListPropTypes = {
  Card: PropTypes.func.isRequired,
  Button: PropTypes.object,
  mCols: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  mWrap: PropTypes.bool,
  lWrap: PropTypes.bool,
}

GridList.propTypes = gridListPropTypes

type GridListProps = PropTypes.InferProps<typeof gridListPropTypes>

export default GridList
