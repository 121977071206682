import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import { reviewByIdRoute } from 'lib/routes'
import MusicCard from '@/components/editorial/cards/music'
import messages from '@/messages/music'
import ViewMoreButton from '@/components/generic/view-more-button'
import QueryResultHandler from '@/components/generic/query-result-handler'
import GET_REVIEWS_WITHOUT_DATE from '@/queries/GetReviewsWithoutDateQuery'
import GridList from '@/components/generic/grid-list/GridList'
import GET_RECOMMENDED_REVIEWS_WITHOUT_DATE from './GetRecommendedReviewsWithoutDateQuery'

const ReviewsSubSection = ({
  type,
  contentType,
  orderBy,
  recommended,
  viewMoreHref,
  variant,
  count = 4,
  mCols = 4,
  title,
  buttonText,
  ...props
}) => {
  const intl = useIntl()

  const queryResult = useQuery(
    recommended
      ? GET_RECOMMENDED_REVIEWS_WITHOUT_DATE
      : GET_REVIEWS_WITHOUT_DATE,
    {
      variables: {
        orderBy,
        count,
        type,
        recommended,
        contentType,
      },
    }
  )

  return (
    <QueryResultHandler
      {...queryResult}
      title={title ?? getTitle(type, orderBy, recommended, intl)}
      dataKey="reviews"
      markup={ReviewsSubSectionMarkup}
      viewMoreHref={viewMoreHref}
      variant={variant}
      props={props}
      mCols={mCols}
      buttonText={buttonText ?? intl.formatMessage(messages.viewMoreReviews)}
    />
  )
}

ReviewsSubSection.propTypes = {
  type: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  recommended: PropTypes.bool,
  viewMoreHref: PropTypes.string.isRequired,
  variant: PropTypes.string,
  contentType: PropTypes.string,
  count: PropTypes.number,
  mCols: PropTypes.number,
  title: PropTypes.string,
  buttonText: PropTypes.string,
}

ReviewsSubSection.defaultProps = {
  recommended: false,
  contentType: 'ALL',
}

const getTitle = (type, orderBy, recommended, intl) => {
  if (recommended) return intl.formatMessage(messages.recommends)
  if (orderBy === 'POPULAR') return intl.formatMessage(messages.popular)

  switch (type) {
    case 'ALBUM':
      return intl.formatMessage(messages.albums)
    case 'SINGLE':
      return intl.formatMessage(messages.singles)
    case 'TECH':
      return intl.formatMessage(messages.tech)
    case 'EVENT':
      return intl.formatMessage(messages.events)
    default:
      return intl.formatMessage(messages.reviews)
  }
}

const ReviewsSubSectionMarkup = ({
  data,
  title,
  viewMoreHref,
  variant,
  mCols,
  buttonText,
}) => {
  return (
    <SubSection.Stacked title={title} variant={variant}>
      <GridList
        Card={(props) => (
          <MusicCard urlFormatter={reviewByIdRoute} {...props} />
        )}
        mCols={mCols}
        items={data}
        Button={
          <ViewMoreButton href={viewMoreHref}>{buttonText}</ViewMoreButton>
        }
      />
    </SubSection.Stacked>
  )
}

ReviewsSubSectionMarkup.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  viewMoreHref: PropTypes.string.isRequired,
  variant: PropTypes.string,
  mCols: PropTypes.number,
  buttonText: PropTypes.string,
}

export { ReviewsSubSectionMarkup }
export default ReviewsSubSection
